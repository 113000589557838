



/* Button styling */

button{
  position: relative;
  width: 10%;
  flex-basis: 50%;
  height: 50px;
  border: none;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin-right: 5%;
}

#button-one{
    color: white;
    background-color: black;
    box-shadow: 1px 1px 3px grey;
}

#button-two{
    color: black;
    background-color: white;
    box-shadow: 1px 1px 3px grey;
}

#button-one:hover{
    color: black;
    background-color: white;
    box-shadow: 2px 2px 3px grey;
    text-decoration: underline;
    cursor: pointer;
    
}

#button-two:hover{
    box-shadow: 2px 2px 3px grey;
    background-color: white;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .content{
      font-size: large;
    }
    button{
      position: relative;
      width: 35%;
    }
  }